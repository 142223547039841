/* Set the A4 paper size in landscape mode */
@media print {
	@page {
		size: A4 landscape;
		margin: 10mm; /* Adjust margins as needed */
	}

	/* Ensure the printed content takes up the entire page */
	.commission-sheet {
		width: 297mm; /* A4 width in landscape */
		height: 210mm; /* A4 height in landscape */
		font-size: 12px; /* Adjust the font size as needed */
	}

	table {
		width: 100%;
		border-collapse: collapse;
	}

	td,
	th {
		border: 1px solid black;
		padding: 5px;
	}

	/* Hide buttons and unnecessary elements in the PDF */
	.no-print {
		display: none;
	}
}

/* Optional: Some styling for screen view */
.commission-sheet {
	max-width: 1000px;
	margin: auto;
}

table {
	width: 100%;
	border-collapse: collapse;
}

td,
th {
	border: 1px solid #ddd;
	padding: 8px;
}

thead td {
	font-weight: bold;
}

tfoot td {
	font-weight: bold;
}

.labelText {
	font-size: smaller;
	opacity: 0.5;
}
